const jsonHelpers = {
    syntaxHighlight: function (json: any): any {
        if (typeof json != "string") {
            json = JSON.stringify(json, undefined, 2)
        }
        json = json.replace(/&/g, "&amp").replace(/</g, "&lt").replace(/>/g, "&gt")
        return json.replace(
            /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
            function (match: string) {
                let cls = "number"
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = "key"
                    } else {
                        cls = "string"
                    }
                } else if (/true|false/.test(match)) {
                    cls = "boolean"
                } else if (/null/.test(match)) {
                    cls = "null"
                }
                return '<span class="' + cls + '">' + match + "</span>"
            }
        )
    },

    getObjectProperty: function (obj: any, props: string) {
        return (props || "").split(".").reduce(function (chain: any, prop: string) {
            chain = chain ? chain[prop] : undefined
            return chain
        }, obj)
    },

    isObjectEmpty: function (obj: any) {
        let result: boolean = true
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                result = false
                break
            }
        }
        return result
    },

    getObjectLength: function (obj: any) {
        let result: number = 0
        for (let key in obj) {
            result++
        }
        return result
    },

    removeDuplicates: function (array: any) {
        var a = array.concat()
        for (let i: number = 0; i < a.length; ++i) {
            for (let j: number = i + 1; j < a.length; ++j) {
                if (a[i] === a[j]) {
                    a.splice(j--, 1)
                }
            }
        }
        return a
    },

    setObjectDeep: function (
        obj: any,
        newObj: any,
        doConcatArrays: boolean = false,
        vueSet: boolean = false
    ): boolean {
        let result: boolean = false
        try {
            if (obj) {
                for (let property in newObj) {
                    let value = newObj[property]
                    if (["number", "string", "boolean"].indexOf(typeof value) != -1) {
                        obj[property] = value
                    } else if (typeof value == "object") {
                        if (Array.isArray(value)) {
                            if (doConcatArrays) {
                                let concatArray: any = obj[property].concat(value)
                                obj[property] = concatArray
                            } else {
                                obj[property] = value
                            }
                        } else {
                            // value is an object
                            if (obj?.hasOwnProperty(property)) {
                                result = jsonHelpers.setObjectDeep(obj[property], value)
                            } else if (obj) {
                                obj[property] = value
                            }
                        }
                    }
                }
            }
            result = true
        } catch (e: any) {
            console.error(e)
        }
        return result
    },

    merge: function (obj: any, newObj: any, doConcatArrays: boolean = false) {
        try {
            for (let property in newObj) {
                let value = newObj[property]
                if (typeof value == "object") {
                    if (Array.isArray(value)) {
                        if (doConcatArrays) {
                            if (obj[property] == undefined) {
                                obj[property] = []
                            }
                            let concatArray: any = obj[property].concat(value)
                            obj[property] = concatArray
                        } else {
                            obj[property] = value
                        }
                    } else {
                        // value is an object
                        if (obj.hasOwnProperty(property)) {
                            if (obj[property] == undefined) {
                                obj[property] = {}
                            }
                            obj[property] = jsonHelpers.merge(obj[property], value, doConcatArrays)
                        } else {
                            obj[property] = value
                        }
                    }
                } else {
                    obj[property] = value
                }
            }
        } catch (e: any) {
            console.error(e)
        }
        return obj
    },

    copyObject: function (object: any) {
        return JSON.parse(JSON.stringify(object))
    },

    jsonSearchPair: function (
        jsonObj: any,
        property: string,
        value: string | number | boolean,
        skipProperties: string[] = []
    ) {
        let result: any = -1
        if (value != undefined) {
            if (typeof jsonObj == "object") {
                if (jsonObj.hasOwnProperty("length")) {
                    // ARRAY
                    for (let index in jsonObj) {
                        if (typeof jsonObj[index] == "object" && jsonObj[index] != null) {
                            result = jsonHelpers.jsonSearchPair(
                                jsonObj[index],
                                property,
                                value,
                                skipProperties
                            )
                        }
                        if (result != -1) {
                            break
                        }
                    }
                } else {
                    // OBJECT
                    for (let thisProperty in jsonObj) {
                        if (skipProperties.indexOf(thisProperty) == -1) {
                            if (thisProperty == property) {
                                if (jsonObj[thisProperty] == value) {
                                    result = jsonObj
                                }
                            } else if (
                                typeof jsonObj[thisProperty] == "object" &&
                                jsonObj[thisProperty] != null
                            ) {
                                result = jsonHelpers.jsonSearchPair(
                                    jsonObj[thisProperty],
                                    property,
                                    value,
                                    skipProperties
                                )
                            }
                            if (result != -1) {
                                break
                            }
                        }
                    }
                }
            } else {
                console.log("please provide an object")
            }
        }
        return result
    },
    swapKeyValue: function (jsonObj: any) {
        var ret: any = {}
        for (let key in jsonObj) {
            ret[jsonObj[key]] = key
        }
        return ret
    },
    getChildrenChain: (
        arrayOfObjects: Array<any>,
        childrenProperty: string = "children",
        targetKey: string = "id",
        targetValue: string,
        currentEntry?: any
    ): any[] => {
        let list: any[] = []
        if (!currentEntry) {
            const foundEntry = arrayOfObjects.find((entry) => {
                return entry[targetKey] != undefined && entry[targetKey] == targetValue
            })
            if (foundEntry) {
                return [foundEntry]
            } else {
                arrayOfObjects.forEach((entry) => {
                    if (entry[childrenProperty]) {
                        let thisResult = jsonHelpers.getChildrenChain(
                            entry[childrenProperty],
                            childrenProperty,
                            targetKey,
                            targetValue,
                            entry
                        )
                        if (thisResult[thisResult.length - 1]?.[targetKey] == targetValue) {
                            let entryCopy = jsonHelpers.copyObject(entry)
                            list.push(entryCopy)
                            list = list.concat(thisResult)
                        }
                    }
                })
            }
        } else {
            if (currentEntry[targetKey] != undefined && currentEntry[targetKey] == targetValue) {
                let entryCopy = jsonHelpers.copyObject(currentEntry)
                list.push(entryCopy)
                return list
            } else if (
                currentEntry[childrenProperty] &&
                Array.isArray(currentEntry[childrenProperty])
            ) {
                const foundEntry = currentEntry[childrenProperty].find((entry) => {
                    return entry[targetKey] != undefined && entry[targetKey] == targetValue
                })
                if (foundEntry) {
                    return [foundEntry]
                } else {
                    arrayOfObjects.forEach((entry) => {
                        if (entry[childrenProperty]) {
                            let thisResult = jsonHelpers.getChildrenChain(
                                entry[childrenProperty],
                                childrenProperty,
                                targetKey,
                                targetValue,
                                entry
                            )
                            if (thisResult[thisResult.length - 1]?.[targetKey] == targetValue) {
                                let entryCopy = jsonHelpers.copyObject(entry)
                                list.push(entryCopy)
                                list = list.concat(thisResult)
                            }
                        }
                    })
                }
            }
        }
        return list
    }
}

export default jsonHelpers
