<script setup lang="ts">
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import localStorageHandler from "@/classes/localStorage"
import TaskLooper from "@/classes/taskLooper"
import { useStore } from "@/store/vuex.store"
import { computed, nextTick, onMounted, ref, useTemplateRef, watch } from "vue"
//@ts-ignore
import resourcePages from "@/resources/resource-pages"
import SlideUpDown from "vue-slide-up-down"
import jsonHelpers from "../../helpers/helpers.json"
import sidebarAccountselect from "./main-sidebar-accountselect.vue"
import MainSidebarEntry from "./main-sidebar-entry.vue"
import MainSidebarLicenseGraph from "./main-sidebar-license-graph.vue"

const refreshCounter = ref(0)
const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})
const accounts = computed(() => {
    return useStore().state.session.accounts
})

const pages = computed(() => {
    return resourcePages
})

const featureStore = useFeatureStore()

// METHODS

const doRefresh = () => {
    refreshCounter.value = refreshCounter.value + 1
    getSubMenusHeight()
}

const licenseGraphVisible = ref(true)
const subMenusHeight = ref(0)
const licenseGraphRef = useTemplateRef("licenseGraph")
const slideUpDownRef = useTemplateRef("slideUpDown")
const getSubMenusHeight = () => {
    let secondaryMenus = document.getElementById("secondaryMenus")
    let height = secondaryMenus?.getBoundingClientRect().height
    if (height) {
        subMenusHeight.value = height
    }
}

onMounted(() => {
    let wasVisible = localStorageHandler.get("licenseGraphVisible")
    if (wasVisible != null && wasVisible == "1") {
        licenseGraphVisible.value = true
    } else if (wasVisible != null && wasVisible == "0") {
        licenseGraphVisible.value = false
    }
    nextTick(() => {
        getSubMenusHeight()
    })
})

watch(
    () => licenseGraphRef.value?.licenseQuota,
    () => {
        nextTick(() => {
            getSubMenusHeight()
        })
    },
    {
        deep: true
    }
)

const submenusTaskLooper = new TaskLooper(1)

watch(licenseGraphVisible, () => {
    submenusTaskLooper.addTask({
        id: "getSubMenusHeight",
        method: getSubMenusHeight
    })
    submenusTaskLooper.startLimiter()
    setTimeout(() => {
        submenusTaskLooper.removeTask("getSubMenusHeight")
    }, 500)
    localStorageHandler.set("licenseGraphVisible", licenseGraphVisible.value ? "1" : "0")
})
watch(refreshCounter, () => {
    nextTick(() => {
        getSubMenusHeight()
    })
})
watch(subMenusHeight, () => {})
defineExpose({
    doRefresh
})
</script>
<template>
    <nav class="menu-vertical" style="z-index: 5">
        <ul
            class="sidebar-menu tenants"
            data-widget="tree"
            :style="
                'min-height:' +
                (subMenusHeight == 0 ? 0 : 'calc(100% - ' + (subMenusHeight + 24) + 'px)')
            "
        >
            <li
                class="select hide-collapsed margin-xs-y"
                v-if="jsonHelpers.getObjectLength(accounts) > 1"
            >
                <div id="selector" class="has-feedback clearfix">
                    <i class="fal fa-building form-control-feedback"></i>
                    <sidebar-accountselect></sidebar-accountselect>
                </div>
            </li>

            <template v-for="page in pages">
                <template v-if="(page.options?.type || null) == 'title'">
                    <li class="title" v-html="T(page.text?.singular)"></li>
                </template>

                <template v-else>
                    <MainSidebarEntry
                        v-if="page.options?.showInSidebar !== false"
                        :page="page"
                    ></MainSidebarEntry>
                </template>
            </template>
        </ul>
        <div id="secondaryMenus">
            <div class="toggleTitle" v-if="featureStore.isEnabled('LICENSE_QUOTA_NAV')">
                <a class="titleToggle" v-on:click="licenseGraphVisible = !licenseGraphVisible">{{
                    licenseGraphVisible ? "-" : "+"
                }}</a
                >&nbsp;
                <a style="color: #fff" v-on:click="licenseGraphVisible = !licenseGraphVisible">
                    {{ T("Gebuchte Geräte auf MDM-Lizenzen") }}
                </a>
            </div>
            <SlideUpDown
                ref="slideUpDown"
                v-if="featureStore.isEnabled('LICENSE_QUOTA_NAV')"
                :active="licenseGraphVisible"
                :duration="500"
            >
                <MainSidebarLicenseGraph ref="licenseGraph"></MainSidebarLicenseGraph>
            </SlideUpDown>
        </div>
    </nav>
</template>
<style lang="scss">
#main-sidebar nav {
    overflow-x: hidden;
}

#main-sidebar nav #secondaryMenus {
    color: #fff;
    padding: 16px 0 16px 16px;
    .toggleTitle {
        font-weight: 600;
        padding: 8px 0;
        a.titleToggle {
            font-weight: 300;
            height: 13px;
            width: 13px;
            display: inline-block;
            border: 1px solid rgba(255, 255, 255, 0.6);
            line-height: 11px;
            border-radius: 3px;
            text-align: center;
            color: #fff;
        }
    }
}
#main-sidebar nav ul li a .label {
    padding: 0 4px;
    margin-right: 1px;
    top: -1px;
    position: relative;
    transition: 0.3s;
}
#main-sidebar nav ul li.active > a .label {
    font-weight: 300;
}
#main-sidebar nav ul li:not(.menu-open) ul li a .label {
    padding: 0;
    font-size: 0;
    line-height: 0;
    min-height: 0px;
    border: 0;
}

#main-sidebar nav ul div hr {
    background: rgba(0, 0, 0, 0.15);
}
.darkmode #main-sidebar nav ul div hr {
    background: rgba(0, 0, 0, 0.2);
}

.menu-separator {
    padding-top: 8px;
    padding-bottom: 8px;
}

.separator-line {
    border-bottom: 1px solid #81838a;
}
</style>
