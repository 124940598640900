<script setup lang="ts">
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import { T } from "@/classes/i18n"
import { useAzureStore } from "@/classes/objectTypes/unifiedSecurity/users/azure"
import getterHelpers from "@/helpers/helpers.getters"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import licenseHelpers from "@/helpers/helpers.license"
import tenantHelpers from "@/helpers/helpers.tenants"
import { PageOptions } from "@/resources/resource-pages"
import useRouterStore from "@/router/routerStore"
import { computed } from "vue"
import SecureDnsNavIcon from "../components/secure-dns-nav-icon.vue"

const props = withDefaults(
    defineProps<{
        page: PageOptions
    }>(),
    {
        page: () => {
            return {
                id: "null"
            }
        }
    }
)
const routerStore = useRouterStore()
const featureStore = useFeatureStore()

const activeAccountId = computed(() => {
    return getterHelpers.useStore().state.session.activeAccountId || ""
})
const isSubmenuOpened = computed(() => {
    return routerStore.isSubmenuOpened(props.page.id)
})
const activePage = computed(() => {
    return routerStore.getActivePage
})

const thisHref = computed(() => {
    let href = props.page.link?.href?.replace(
        "{tenantDomain}",
        tenantHelpers.getTenantDomain(activeAccountId.value)
    )
    return href
})

const useAddLink = (page: any) => {
    let result: boolean = false
    if (page.addLink != undefined) {
        result = true
        if (page.addLink.forLicenses) {
            if (
                !licenseHelpers.hasOneOfLicenses(
                    activeAccountId.value,
                    page.addLink.forLicenses
                ) === true
            ) {
                result = false
            }
        }
        if (
            (page.text?.plural == "Other devices (VPN)" || page.text?.plural == "Devices") &&
            getterHelpers.useStore().state.session.accounts[activeAccountId.value].mobileSecurity
                .settings.enrollmentAllowed === false
        ) {
            result = false
        }
        if (page.addLink.conditions) {
            if (!jsonFormHelpers.areConditionsFulfilled(this, page.addLink.conditions, undefined)) {
                result = false
            }
        }
    }
    return result
}
const checkAddLink = (addLinkText: string | undefined) => {
    let result: boolean = true
    if (
        (addLinkText == "Add role" || addLinkText == "Add user") &&
        useAzureStore().isAzureImporting
    ) {
        result = false
    }
    return result
}

const isPageVisible = computed(() => {
    const page = props.page

    if (page.options?.showInSidebar == false) {
        return false
    }
    if (
        page.options?.activeAccountHasNoParentAccount &&
        getterHelpers.useStore().state.session.accounts[activeAccountId.value || ""]
            ?.unifiedSecurity.account.parent_accountid != null
    ) {
        return false
    }
    if (
        page.options?.resellerAccountOnly &&
        getterHelpers.useStore().state.session.isReseller != true
    ) {
        return false
    }
    if (
        page.options?.resellerTenantOnly &&
        getterHelpers.useStore().state.session.accounts[activeAccountId.value || ""].unifiedSecurity
            .account.isReseller != true
    ) {
        return false
    }
    if (
        page.options?.vppOnly &&
        tenantHelpers.hasFunctionality(activeAccountId.value, "vpp") != true
    ) {
        return false
    }
    if (
        page.options?.depOnly &&
        tenantHelpers.hasFunctionality(activeAccountId.value, "dep") != true
    ) {
        return false
    }
    if (
        page.options?.enterpriseOnly &&
        tenantHelpers.hasFunctionality(activeAccountId.value, "enterprise") != true
    ) {
        return false
    }
    if (
        page.options?.zeroTouchOnly &&
        tenantHelpers.hasFunctionality(activeAccountId.value, "zerotouch") != true
    ) {
        return false
    }
    if (page.options?.forRoles) {
        // check for roles

        if (
            !getterHelpers.useStore().state.session.userInfo.roles.some((roleName: string) => {
                return page.options?.forRoles?.indexOf(roleName) != -1
            })
        ) {
            return false
        }
    }
    if (page.options?.devOnly == true && config.devMode != true) {
        return false
    }
    if (page.options?.featureFlag && featureStore.isEnabled(page.options.featureFlag) === false) {
        return false
    }

    if (page.options?.forLicenses) {
        if (
            !licenseHelpers.hasOneOfLicenses(activeAccountId.value, page.options.forLicenses) ===
            true
        ) {
            return false
        }
    }
    if (page.options?.forValidLicenses) {
        if (
            !licenseHelpers.hasOneOfLicenses(
                activeAccountId.value,
                page.options.forValidLicenses
            ) === true
        ) {
            return false
        }
    }

    if (page.options?.forLicensesInAnyTenant) {
        let accountIds: string[] = getterHelpers
            .useStore()
            .state.session.userInfo.scopes.list_accounts.map(function (accountInfo: any) {
                return accountInfo.accountid
            })
        let foundLicense = accountIds.some((accountId) => {
            //@ts-ignore
            return licenseHelpers.hasOneOfLicenses(
                accountId,
                page.options?.forLicensesInAnyTenant || []
            )
        })

        if (!foundLicense) {
            return false
        }
    }
    if (page.options?.forValidLicensesInAnyTenant) {
        let accountIds: string[] = getterHelpers
            .useStore()
            .state.session.userInfo.scopes.list_accounts.map(function (accountInfo: any) {
                return accountInfo.accountid
            })
        let foundLicense = accountIds.some((accountId) => {
            //@ts-ignore
            return licenseHelpers.hasOneOfLicenses(
                accountId,
                page.options?.forValidLicensesInAnyTenant || [],
                "valid"
            )
        })

        if (!foundLicense) {
            return false
        }
    }

    if (page.options?.forLicensesInAnyChildOrThisTenant) {
        let accountIds: string[] = getterHelpers
            .useStore()
            .state.session.userInfo.scopes.list_accounts.filter((accountInfo: any) => {
                return (
                    String(accountInfo.parent_accountid) == activeAccountId.value ||
                    String(accountInfo.accountid) == activeAccountId.value
                )
            })
            .map(function (accountInfo: any) {
                return String(accountInfo.accountid)
            })
        let foundLicense = accountIds.some((accountId) => {
            //@ts-ignore
            return licenseHelpers.hasOneOfLicenses(
                accountId,
                page.options?.forLicensesInAnyChildOrThisTenant || []
            )
        })
        if (!foundLicense) {
            return false
        }
    }
    if (page.options?.forValidLicensesInAnyChildOrThisTenant) {
        let accountIds: string[] = getterHelpers
            .useStore()
            .state.session.userInfo.scopes.list_accounts.filter((accountInfo: any) => {
                return (
                    String(accountInfo.parent_accountid) == activeAccountId.value ||
                    String(accountInfo.accountid) == activeAccountId.value
                )
            })
            .map(function (accountInfo: any) {
                return String(accountInfo.accountid)
            })

        let foundLicense = accountIds.some((accountId) => {
            return licenseHelpers.hasOneOfLicenses(
                accountId,
                page.options?.forValidLicensesInAnyChildOrThisTenant || [],
                "valid"
            )
        })

        if (!foundLicense) {
            return false
        }
    }
    return true
})

const onButtonClick = () => {
    if (props.page.children != undefined) {
        routerStore.toggleSubmenu(props.page.id)
    }
}
</script>
<template>
    <template v-if="isPageVisible">
        <li
            v-bind:class="{
                treeview: page.children?.length,
                active: activePage == page.id,
                'menu-open': isSubmenuOpened
            }"
        >
            <a
                :href="thisHref || undefined"
                :target="thisHref ? page.link?.target : undefined"
                :title="T(page.text?.sidebar || page.text?.plural)"
                :id="'linkTo' + page.id"
                :onclick="page.link?.onClick"
                v-on:click="onButtonClick"
            >
                <secure-dns-nav-icon v-if="page.id === 'secureDns'" />
                <i v-else :class="page.appearance?.icon" class="fa-fw"></i>

                <span class="title">
                    {{ T(page.text?.sidebar || page.text?.plural) }}
                    <template v-if="page.appearance?.labels">
                        <template v-for="label in page.appearance?.labels">
                            <small
                                v-if="
                                    label.featureFlag
                                        ? featureStore.isEnabled(label.featureFlag)
                                        : true
                                "
                                class="label"
                                :class="label.class"
                                :title="T(label.title)"
                            >
                                {{ T(label.text) }}
                            </small>
                        </template>
                    </template>
                </span>
                <span class="pull-right-container hide-collapsed" v-if="page.children?.length">
                    <i class="fal fa-fw fa-angle-left"></i>
                </span>
                <a
                    class="addLink"
                    style="float: right"
                    v-if="useAddLink(page) && checkAddLink(page.text?.add)"
                    :title="T(page.text?.add)"
                    :href="
                        page.addLink?.href?.replace(
                            '{tenantDomain}',
                            tenantHelpers.getTenantDomain(activeAccountId)
                        )
                    "
                >
                    <i class="fal fa-fw fa-plus-circle"></i>
                </a>
            </a>

            <ul v-if="page.children?.length" class="treeview-menu">
                <template v-for="childPage in page.children">
                    <MainSidebarEntry
                        v-if="childPage.options?.showInSidebar !== false"
                        :page="childPage"
                    ></MainSidebarEntry>
                </template>
            </ul>
        </li>
        <div class="menu-separator padding-xs-x-2" v-if="page?.appearance?.lineAfter == true">
            <div class="separator-line"></div>
        </div>
    </template>
</template>
