import { type LabelOptions } from "@/templates/components/label/label"

export type PageOptions = {
    id: string
    text?: {
        singular?: string
        plural?: string
        add?: string
        edit?: string
        details?: string
        sidebar?: string
        addVpp?: string
        editVpp?: string
        editDevice?: string
        editVPN?: string
        editEnterprise?: string
        editReportingUtm?: string
        editCCUtm?: string
    }
    link?: {
        href?: string
        target?: string
        onClick?: string
    }
    addLink?: {
        href?: string
        target?: string
        conditions?: [{ root: "canUseEnterprise"; value: boolean }]
    }
    appearance?: {
        color?: string
        icon?: string
        label?: LabelOptions
        labels?: LabelOptions[]
        lineAfter?: boolean
    }
    options?: {
        type?: "title"
        showInSidebar?: boolean
        showOnDashboard?: boolean
        productType?: string
        devOnly?: boolean
        featureFlag?: string
        resellerTenantOnly?: boolean
        vppOnly?: boolean
        depOnly?: boolean
        enterpriseOnly?: boolean
        zeroTouchOnly?: boolean
        activeAccountHasNoParentAccount?: boolean
        resellerAccountOnly?: boolean
        requiresSession?: boolean
        forLicenses?: string[]
        forValidLicenses?: string[]
        forLicensesInAnyTenant?: string[]
        forValidLicensesInAnyTenant?: string[]
        forLicensesInAnyChildOrThisTenant?: string[]
        forValidLicensesInAnyChildOrThisTenant?: string[]
        forRoles?: string[]
    }
    children?: PageOptions[]
}

const resourcePages: PageOptions[] = [
    {
        id: "tenant-dashboard",
        text: {
            singular: "Dashboard",
            plural: "Dashboard"
        },
        link: {
            href: "#show-tenant-{tenantDomain}-dashboard",
            target: "_self"
        },
        appearance: {
            color: "green",
            icon: "fal fa-tachometer-alt"
        },
        options: {
            showOnDashboard: false,
            requiresSession: true
        }
    },
    {
        id: "mobSec",
        text: {
            singular: "Mobile Security",
            plural: "Mobile Security",
            add: "Mobile Security",
            edit: "Mobile Security"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-mobile"
        },
        options: {
            showOnDashboard: false,
            forLicensesInAnyChildOrThisTenant: ["Mobile Security", "MDM"],
            requiresSession: true
        },
        children: [
            {
                id: "msDashboardTree",
                text: {
                    singular: "Dashboards",
                    plural: "Dashboards",
                    add: "Dashboards",
                    edit: "Dashboards"
                },
                link: {
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-sitemap"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: false,
                    forLicensesInAnyChildOrThisTenant: ["Mobile Security", "MDM"],
                    requiresSession: true
                },
                children: [
                    {
                        id: "msDashboard",
                        text: {
                            sidebar: "All Tenants",
                            plural: "Mobile Security Dashboard - All Tenants",
                            singular: "Mobile Security Dashboard - All Tenants",
                            details: "Mobile Security Dashboard - All Tenants",
                            edit: "Mobile Security Dashboard - All Tenants"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-mobile-security-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-tachometer-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            resellerTenantOnly: true,
                            showOnDashboard: false,
                            forLicensesInAnyChildOrThisTenant: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "msMyDashboard",
                        text: {
                            sidebar: "My Mobile Security",
                            plural: "Mobile Security Dashboard",
                            singular: "Mobile Security Dashboard",
                            details: "Mobile Security Dashboard",
                            edit: "Mobile Security Dashboard"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-my-mobile-security-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-tachometer-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            showOnDashboard: false,
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    }
                ]
            },
            {
                id: "ios",
                text: {
                    plural: "iOS / iPadOS",
                    singular: "iOS / iPadOS"
                },
                link: {
                    target: "_self"
                },
                appearance: {
                    color: "darkblue",
                    icon: "fab fa-apple"
                },
                options: {
                    showOnDashboard: false,
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                },
                children: [
                    {
                        id: "iosDevices",
                        text: {
                            plural: "Devices",
                            singular: "Device",
                            details: "Device details",
                            add: "Add device",
                            edit: "Edit device"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-ios-devices-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#enroll-tenant-{tenantDomain}-ios-device",
                            target: "_self"
                        },
                        appearance: {
                            color: "green",
                            icon: "fal fa-mobile-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "iosProfiles",
                        text: {
                            singular: "Profile",
                            plural: "Profiles",
                            add: "Add profile",
                            edit: "Edit profile"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-ios-profiles-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#add-tenant-{tenantDomain}-ios-profile",
                            target: "_self"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-shield-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "iosApps",
                        text: {
                            singular: "App",
                            plural: "Apps",
                            add: "Add app",
                            edit: "Edit app"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-ios-apps-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#add-tenant-{tenantDomain}-ios-app",
                            target: "_self"
                        },
                        appearance: {
                            color: "green",
                            icon: "fal fa-grid-2-plus"
                        },
                        options: {
                            productType: "mobileSecurity",
                            showOnDashboard: true,
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "homescreenlayouts",
                        text: {
                            singular: "Home Screen Layout",
                            plural: "Home Screen Layouts",
                            add: "Add Home Screen Layout",
                            edit: "Edit Home Screen Layout"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-homescreenlayouts-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "green",
                            icon: "fal fa-mobile"
                        },
                        options: {
                            productType: "mobileSecurity",
                            showOnDashboard: true,
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "vppassets",
                        text: {
                            singular: "VPP License",
                            plural: "VPP Licenses",
                            details: "VPP Licenses"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-vpp-assets-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-rocket"
                        },
                        options: {
                            productType: "mobileSecurity",
                            showOnDashboard: false,
                            forLicenses: ["Mobile Security", "MDM"],
                            vppOnly: true,
                            requiresSession: true
                        }
                    },
                    {
                        id: "depProfiles",
                        text: {
                            singular: "DEP Profile",
                            plural: "DEP Profiles",
                            add: "Add profile",
                            edit: "Edit profile"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-dep-profiles-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#add-tenant-{tenantDomain}-dep-profile",
                            target: "_self"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-shield-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            showOnDashboard: true,
                            forLicenses: ["Mobile Security", "MDM"],
                            depOnly: true,
                            requiresSession: true
                        }
                    }
                ]
            },

            {
                id: "android",
                text: {
                    plural: "Android",
                    singular: "Android"
                },
                link: {
                    target: "_self"
                },
                appearance: {
                    color: "green",
                    icon: "fab fa-android"
                },
                options: {
                    showOnDashboard: false,
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                },
                children: [
                    {
                        id: "androidDevices",
                        text: {
                            plural: "Devices",
                            singular: "Device",
                            details: "Device details",
                            add: "Add device",
                            edit: "Edit device"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-android-devices",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#enroll-tenant-{tenantDomain}-android-device",
                            target: "_self",
                            conditions: [
                                {
                                    root: "canUseEnterprise",
                                    value: true
                                }
                            ]
                        },
                        appearance: {
                            color: "green",
                            icon: "fal fa-mobile-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "androidProfiles",
                        text: {
                            singular: "Profile",
                            plural: "Profiles",
                            add: "Add profile",
                            edit: "Edit profile"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-android-profiles-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#add-tenant-{tenantDomain}-android-profile",
                            target: "_self",
                            conditions: [
                                {
                                    root: "canUseEnterprise",
                                    value: true
                                }
                            ]
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-shield-alt"
                        },
                        options: {
                            productType: "mobileSecurity",
                            forLicenses: ["Mobile Security", "MDM"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "zeroTouchConfigurations",
                        text: {
                            singular: "Zero-Touch configuration",
                            plural: "Zero-Touch",
                            add: "Add Zero-Touch configuration",
                            edit: "Edit Zero-Touch configuration"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-zerotouchconfigurations-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        addLink: {
                            href: "#add-tenant-{tenantDomain}-zerotouchconfiguration",
                            target: "_self",
                            conditions: [
                                {
                                    root: "canUseEnterprise",
                                    value: true
                                }
                            ]
                        },
                        appearance: {
                            color: "red",
                            icon: "fab fa-android"
                        },
                        options: {
                            productType: "mobileSecurity",
                            forLicenses: ["Mobile Security", "MDM"],
                            zeroTouchOnly: true,
                            requiresSession: true
                        }
                    }
                ]
            },

            {
                id: "vpns",
                text: {
                    singular: "Other device (VPN)",
                    plural: "Other devices (VPN)",
                    add: "Add other device (VPN)",
                    edit: "Edit other device (VPN)"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-vpns-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-vpn",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-laptop"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: true,
                    forLicenses: ["Mobile Security"],
                    requiresSession: true
                }
            },
            {
                id: "certificates",
                text: {
                    singular: "Certificate",
                    plural: "Certificates",
                    add: "Add certificate",
                    edit: "Edit certificate"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-certificates-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-certificate",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-certificate"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: true,
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "stats-dashboard",
                text: {
                    singular: "Statistic",
                    plural: "Statistics"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-stats-dashboard",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-chart-area"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: false,
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "wiki",
                text: {
                    plural: "Wiki",
                    singular: "Wiki"
                },
                link: {
                    href: "https://wiki.securepoint.de/MS",
                    target: "_blank"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-question-circle"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: false,
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "mobileSecuritySettings",
                text: {
                    plural: "Settings",
                    singular: "Settings"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-mobile-security-settings",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-cog"
                },
                options: {
                    productType: "mobileSecurity",
                    showOnDashboard: false,
                    requiresSession: true
                }
            }
        ]
    },
    {
        id: "secureDns",
        text: {
            singular: "Cloud Shield",
            plural: "Cloud Shield"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            labels: [
                {
                    text: "Beta",
                    class: "bg-blue",
                    featureFlag: "BETA_LABEL_SECURE_DNS"
                },
                {
                    text: "New",
                    class: "bg-green",
                    featureFlag: "NEW_LABEL_SECURE_DNS"
                }
            ]
        },
        options: {
            showOnDashboard: true,
            featureFlag: "SECURE_DNS",
            requiresSession: true,
            forLicenses: ["MDM", "Mobile Security"]
        },
        children: [
            {
                id: "secureDnsProfiles",
                text: {
                    singular: "Profile",
                    plural: "Profiles",
                    add: "Add profile",
                    edit: "Edit profile"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDnsProfiles",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-secureDnsProfiles",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-shield-alt"
                },
                options: {
                    featureFlag: "SECURE_DNS",
                    productType: "secureDns",
                    showOnDashboard: true,
                    requiresSession: true,
                    forLicenses: ["MDM", "Mobile Security"]
                }
            },
            {
                id: "secureDns-stats",
                text: {
                    singular: "Statistic",
                    plural: "Statistics"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDns-stats",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-chart-area"
                },
                options: {
                    productType: "secureDns",
                    showOnDashboard: true,
                    featureFlag: "SECURE_DNS",
                    requiresSession: true,
                    forLicenses: ["MDM", "Mobile Security"]
                }
            },
            {
                id: "secureDns-logs",
                text: {
                    singular: "Log",
                    plural: "Logs"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDns-logs",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-history"
                },
                options: {
                    productType: "secureDns",
                    showOnDashboard: false,
                    featureFlag: "SECURE_DNS",
                    requiresSession: true,
                    forLicenses: ["MDM", "Mobile Security"]
                }
            },
            {
                id: "secureDns-config-status",
                text: {
                    singular: "Configuration Status",
                    plural: "Configuration Status"
                },
                link: {
                    href: "#cloud-shield-status",
                    target: "_blank"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-shield-check"
                },
                options: {
                    productType: "secureDns",
                    showOnDashboard: true,
                    featureFlag: "SECURE_DNS_CONFIG_STATUS",
                    requiresSession: true,
                    forLicenses: ["MDM", "Mobile Security"]
                }
            }
        ]
    },

    {
        id: "laas",
        text: {
            singular: "Unified Reporting",
            plural: "Unified Reporting",
            add: "Unified Reporting",
            edit: "Unified Reporting"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-chart-pie"
        },
        options: {
            showOnDashboard: false,
            forValidLicensesInAnyChildOrThisTenant: ["laas"],
            requiresSession: true
        },
        children: [
            {
                id: "usrUtms",
                text: {
                    plural: "UTMs",
                    singular: "UTM",
                    edit: "Edit UTM"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usrutms-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-server"
                },
                options: {
                    productType: "unifiedReporting",
                    showOnDashboard: true,
                    forValidLicenses: ["laas"],
                    requiresSession: true
                }
            },
            {
                id: "reports",
                text: {
                    plural: "Reports",
                    singular: "Report",
                    edit: "Edit report"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-reports-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-file-alt"
                },
                options: {
                    productType: "unifiedReporting",
                    showOnDashboard: true,
                    forValidLicenses: ["laas"],
                    requiresSession: true
                }
            },
            {
                id: "branding",
                text: {
                    singular: "Branding",
                    plural: "Branding",
                    add: "Add branding",
                    edit: "Edit branding"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-branding",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-paint-brush"
                },
                options: {
                    productType: "unifiedReporting",
                    showOnDashboard: false,
                    forLicensesInAnyTenant: ["laas"],
                    activeAccountHasNoParentAccount: true,
                    forRoles: ["__RESELLER__", "__RESELLER_TECH__", "__SUPPORT__", "admin"],
                    requiresSession: true
                }
            }
        ]
    },

    {
        id: "unifiedSecurityConsole",
        text: {
            singular: "Unified Security Console",
            plural: "Unified Security Console",
            add: "Unified Security Console",
            edit: "Unified Security Console"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-server"
        },
        options: {
            showOnDashboard: false,
            forLicensesInAnyChildOrThisTenant: ["utmLicense", "MDM", "Mobile Security"],
            requiresSession: true
        },
        children: [
            {
                id: "uscDashboardTree",
                text: {
                    singular: "Dashboards",
                    plural: "Dashboards",
                    add: "Dashboards",
                    edit: "Dashboards"
                },
                link: {
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-sitemap"
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: false,
                    forLicensesInAnyChildOrThisTenant: ["unifiedSecurityConsole"],
                    requiresSession: true
                },
                children: [
                    {
                        id: "uscDashboard",
                        text: {
                            sidebar: "All Tenants",
                            plural: "Unified Security Console Dashboard - All Tenants",
                            singular: "Unified Security Console Dashboard - All Tenants",
                            details: "Unified Security Console Dashboard - All Tenants",
                            edit: "Unified Security Console Dashboard - All Tenants"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-usc-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-tachometer-alt"
                        },
                        options: {
                            productType: "unifiedSecurityConsole",
                            resellerTenantOnly: true,
                            showOnDashboard: false,
                            forLicensesInAnyChildOrThisTenant: ["unifiedSecurityConsole"],
                            requiresSession: true
                        }
                    },
                    {
                        id: "uscMyDashboard",
                        text: {
                            sidebar: "My USC",
                            plural: "Unified Security Console Dashboard",
                            singular: "Unified Security Console Dashboard",
                            details: "Unified Security Console Dashboard",
                            edit: "Unified Security Console Dashboard"
                        },
                        link: {
                            href: "#show-tenant-{tenantDomain}-my-usc-dashboard",
                            target: "_self",
                            onClick: "vueApp.setItemlistSearchString('')"
                        },
                        appearance: {
                            color: "red",
                            icon: "fal fa-tachometer-alt"
                        },
                        options: {
                            productType: "unifiedSecurityConsole",
                            showOnDashboard: false,
                            forLicenses: ["unifiedSecurityConsole"],
                            requiresSession: true
                        }
                    }
                ]
            },
            {
                id: "adaptiveSecureConnectConfiguration",
                text: {
                    plural: "VPN-Configuration",
                    singular: "VPN-Configuration"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-vpn-configuration",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-puzzle",
                    labels: []
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: false,
                    forLicenses: ["unifiedSecurityConsole"],
                    requiresSession: true
                }
            },
            {
                id: "uscUtms",
                text: {
                    plural: "UTMs",
                    singular: "UTM",
                    details: "UTM Details",
                    edit: "Edit UTM"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-utms-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-server"
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: true,
                    forLicenses: ["unifiedSecurityConsole"],
                    requiresSession: true
                }
            },
            {
                id: "uscProfiles",
                text: {
                    plural: "UTM Profiles",
                    singular: "UTM Profile",
                    details: "UTM Profile Details",
                    add: "Add profile",
                    edit: "Edit profile"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-usc-profiles",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-shield"
                },
                options: {
                    productType: " ",
                    showOnDashboard: false,
                    forLicensesInAnyChildOrThisTenant: ["unifiedSecurityConsole"],
                    requiresSession: true
                }
            },
            {
                id: "uscZtProfiles",
                text: {
                    plural: "UTM Zero-Touch",
                    singular: "Zero-Touch Profile",
                    details: "Zero-Touch Profile Details",
                    add: "Add Zero-Touch Profile",
                    edit: "Edit Zero-Touch Profile"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-uscZtProfiles",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-uscZtProfiles",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-box-check",
                    labels: [
                        {
                            text: "Beta",
                            class: "bg-blue",
                            featureFlag: "BETA_LABEL_UTM_ZERO_TOUCH"
                        },
                        {
                            text: "New",
                            class: "bg-green",
                            featureFlag: "NEW_LABEL_UTM_ZERO_TOUCH"
                        }
                    ]
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: false,
                    forLicensesInAnyChildOrThisTenant: ["utmLicense"],
                    featureFlag: "UTM_ZERO_TOUCH",
                    requiresSession: true
                }
            },
            {
                id: "windowsVpns",
                text: {
                    singular: "Windows VPN-Client",
                    plural: "Windows VPN-Clients",
                    add: "Add Windows VPN-Client",
                    edit: "Edit Windows VPN-Client",
                    details: "Windows VPN-Client details"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-vpns-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-laptop",
                    labels: [
                        {
                            text: "Beta",
                            class: "bg-blue",
                            featureFlag: "BETA_LABEL_WINDOWS_DEVICES"
                        },
                        {
                            text: "New",
                            class: "bg-green",
                            featureFlag: "NEW_LABEL_WINDOWS_DEVICES"
                        }
                    ]
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: true,
                    featureFlag: "WINDOWS_DEVICES",
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "windowsProfiles",
                text: {
                    singular: "Windows Profile",
                    plural: "Windows Profiles",
                    add: "Add Windows Profile",
                    edit: "Edit Windows Profile",
                    details: "Windows Profile details"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-windows-profiles",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-shield-alt",
                    labels: [
                        {
                            text: "Beta",
                            class: "bg-blue",
                            featureFlag: "BETA_LABEL_WINDOWS_DEVICES"
                        },
                        {
                            text: "New",
                            class: "bg-green",
                            featureFlag: "NEW_LABEL_WINDOWS_DEVICES"
                        }
                    ]
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: true,
                    featureFlag: "WINDOWS_DEVICE_PROFILES",
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "windowsEnrollmentTokens",
                text: {
                    singular: "Windows Enrollmentx",
                    plural: "Windows Enrollmentx",
                    add: "Add Windows enrollment token",
                    edit: "Edit Windows enrollment token"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-enrollmenttokens-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-windows-enrollmenttoken",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-sign-in",
                    labels: [
                        {
                            text: "Beta",
                            class: "bg-blue",
                            featureFlag: "BETA_LABEL_WINDOWS_DEVICES"
                        },
                        {
                            text: "New",
                            class: "bg-green",
                            featureFlag: "NEW_LABEL_WINDOWS_DEVICES"
                        }
                    ]
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: true,
                    featureFlag: "WINDOWS_ENROLLMENTTOKENS",
                    forLicenses: ["Mobile Security", "MDM"],
                    requiresSession: true
                }
            },
            {
                id: "unifiedSecurityConsoleSettings",
                text: {
                    plural: "Settings",
                    singular: "Settings"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-unified-security-console-settings",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-cog"
                },
                options: {
                    productType: "unifiedSecurityConsole",
                    showOnDashboard: false,
                    requiresSession: true,
                    featureFlag: "USC_OPTIONS",
                    resellerTenantOnly: true
                }
            }
        ]
    },
    {
        id: "general",
        text: {
            singular: "General",
            plural: "General",
            add: "General",
            edit: "General"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-list",
            lineAfter: true
        },
        options: {
            showOnDashboard: false,
            requiresSession: true
        },
        children: [
            {
                id: "users",
                text: {
                    singular: "User",
                    plural: "Users",
                    add: "Add user",
                    edit: "Edit user",
                    addVpp: "Add Apple VPP user",
                    editVpp: "Edit Apple VPP user",
                    details: "User details"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-users-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-user",
                    target: "_self"
                },
                appearance: {
                    color: "lightblue",
                    icon: "fal fa-user"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: true,
                    requiresSession: true
                }
            },
            {
                id: "roles",
                text: {
                    singular: "Role",
                    plural: "Roles",
                    add: "Add role",
                    edit: "Edit role"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-roles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-role",
                    target: "_self"
                },
                appearance: {
                    color: "yellow",
                    icon: "fal fa-users"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: true,
                    requiresSession: true
                }
            },
            {
                id: "microsoftEntraId",
                text: {
                    plural: "Microsoft Entra ID",
                    singular: "Microsoft Entra ID"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-microsoft-entra-id",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fab fa-fw fa-microsoft"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "notifications",
                text: {
                    singular: "Notification",
                    plural: "Notifications",
                    add: "Add notification",
                    edit: "Edit notification"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-notifications-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-notification",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-comment-alt"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: true,
                    requiresSession: true
                }
            },
            {
                id: "apiKeys",
                text: {
                    singular: "API Key",
                    plural: "API Keys",
                    add: "Add API Key",
                    edit: "Edit API Key"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-apikeys-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                addLink: {
                    href: "#add-tenant-{tenantDomain}-apikey",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-key"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: true,
                    requiresSession: true
                }
            },
            {
                id: "inventory",
                text: {
                    singular: "Inventory",
                    plural: "Inventory",
                    editDevice: "Edit device",
                    editVPN: "Edit other device (VPN)",
                    editEnterprise: "Edit device",
                    editReportingUtm: "Edit UTM",
                    editCCUtm: "Edit UTM"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-inventory-dashboard",
                    target: "_self"
                },
                appearance: {
                    color: "green",
                    icon: "fal fa-list-alt"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "warnings",
                text: {
                    singular: "Warning",
                    plural: "Warnings"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-warnings",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-bell"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "licenses",
                text: {
                    singular: "License",
                    plural: "Licenses",
                    add: "Add license",
                    edit: "Edit license",
                    details: "License"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-licenses-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-key"
                },
                options: {
                    productType: "unifiedSecurity",
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "privacy",
                text: {
                    plural: "Privacy",
                    singular: "Privacy"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-privacylevel-config",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-balance-scale"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "auditlog",
                text: {
                    plural: "Auditlog",
                    singular: "Auditlog"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-audit-log",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-clipboard-list"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "log",
                text: {
                    plural: "Log",
                    singular: "Log"
                },
                link: {
                    target: "_self",
                    onClick: "app.navigation.toggleLog()"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-code"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            },
            {
                id: "account-info",
                text: {
                    singular: "Info",
                    plural: "Info",
                    add: "Add info",
                    edit: "Edit info"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-account-info",
                    target: "_self"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-info-circle"
                },
                options: {
                    showOnDashboard: false,
                    requiresSession: true
                }
            }
        ]
    },

    {
        id: "threatVisualizer",
        text: {
            singular: "Threat Visualizer",
            plural: "Threat Visualizer"
        },
        link: {
            href: "#show-tenant-{tenantDomain}-threat-visualizer",
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-map-location-dot",
            lineAfter: true,
            labels: [
                {
                    text: "New",
                    class: "bg-green"
                }
            ]
        },
        options: {
            showOnDashboard: true,
            featureFlag: "GLOBAL_THREAT_VISUALIZER",
            requiresSession: true
        }
    },
    {
        id: "reseller-portal",
        text: {
            plural: "Resellerportal",
            singular: "Resellerportal"
        },
        link: {
            href: "https://my.securepoint.de",
            target: "_blank"
        },
        appearance: {
            color: "red",
            icon: "fal fa-external-link-alt"
        },
        options: {
            showOnDashboard: false,
            resellerAccountOnly: true,
            requiresSession: true
        }
    },
    {
        id: "antivirus",
        text: {
            plural: "Antivirus",
            singular: "Antivirus"
        },
        link: {
            href: "https://av.securepoint.de/",
            target: "_blank"
        },
        appearance: {
            color: "red",
            icon: "fal fa-external-link-alt"
        },
        options: {
            showOnDashboard: false,
            resellerAccountOnly: true,
            requiresSession: true
        }
    },
    {
        id: "spdyn",
        text: {
            plural: "spDyn",
            singular: "spDyn"
        },
        link: {
            href: "https://spdyn.de/",
            target: "_blank"
        },
        appearance: {
            color: "red",
            icon: "fal fa-external-link-alt"
        },
        options: {
            showOnDashboard: false,
            resellerAccountOnly: true,
            requiresSession: true
        }
    },
    {
        id: "awarenessPlus",
        text: {
            plural: "Awareness Plus",
            singular: "Awareness Plus"
        },
        link: {
            href: "https://api.sosafe.de/v1/securepoint/auth",
            target: "_blank"
        },
        appearance: {
            color: "red",
            icon: "fal fa-user-shield",
            lineAfter: true
        },
        options: {
            showOnDashboard: false,
            resellerAccountOnly: true,
            requiresSession: true
        }
    },
    {
        id: "refresh",
        text: {
            plural: "Refreshing",
            singular: "Refreshing"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-sync"
        },
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: true
        }
    },
    {
        id: "pluginLicenses",
        text: {
            plural: "Licenses",
            singular: "License"
        },
        link: {
            href: "#pluginLicenses",
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-key"
        },
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    },
    {
        id: "email-subscription",
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    },
    {
        id: "password-reset",
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    },
    {
        id: "android-code-enrollment",
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    },
    {
        id: "ios-code-enrollment",
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    },
    {
        id: "sandbox",
        text: {
            plural: "Sandkiste",
            singular: "Sandkiste"
        },
        link: {
            href: "#sandbox",
            target: "_self"
        },
        appearance: {
            color: "yellow",
            icon: "fal fa-fill"
        },
        options: {
            showOnDashboard: false,
            devOnly: true,
            requiresSession: true
        }
    },
    {
        id: "docs",
        text: {
            plural: "Docs",
            singular: "Documentation"
        },
        link: {
            href: "#docs",
            target: "_self"
        },
        appearance: {
            color: "blue",
            icon: "fal fa-fill",
            lineAfter: true
        },
        options: {
            showOnDashboard: false,
            devOnly: true,
            requiresSession: true
        }
    },
    {
        id: "logout",
        text: {
            plural: "Sign out",
            singular: "Sign out"
        },
        link: {
            href: "#logout",
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-sign-out-alt"
        },
        options: {
            showOnDashboard: false,
            requiresSession: true
        }
    },
    {
        id: "404",
        text: {
            plural: "Not found",
            singular: "Not found"
        },
        link: {
            target: "_self"
        },
        appearance: {
            color: "red",
            icon: "fal fa-ban"
        },
        options: {
            showOnDashboard: false,
            showInSidebar: false,
            requiresSession: false
        }
    }
]

export default resourcePages
