import { useVue } from "@/app"
import config from "@/classes/config"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import dialogs from "@/dialogs/dialogs"
import miscDialogs from "@/dialogs/dialogs.misc"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { nextTick } from "vue"
import useRouterStore from "./routerStore"

interface Router {
    routes: any[]
    mode: string | null
    root: string
    lastFragment: string
    interval: any
    config(options: any): void
    getFragment(): string
    clearSlashes(path: string): string
    add(re: RegExp, handler: Function): any
    remove(param: any): any
    flush(): any
    check(f: string): any
    listen(): any
    navigate(path: string): any
}
export const useRouter = () => {
    return Router || undefined
}
var Router = <Router>{
    routes: [],
    mode: null,
    root: "/",
    lastFragment: "",
    interval: <any>undefined,
    config: function (options: any) {
        this.mode =
            options && options.mode && options.mode == "history" && !!history.pushState
                ? "history"
                : "hash"
        this.root = options && options.root ? "/" + this.clearSlashes(options.root) + "/" : "/"
        return this
    },
    getFragment: function () {
        var fragment = ""
        if (this.mode === "history") {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search))
            fragment = fragment.replace(/\?(.*)$/, "")
            fragment = this.root != "/" ? fragment.replace(this.root, "") : fragment
        } else {
            var match = window.location.href.match(/#(.*)$/)
            fragment = match ? match[1] : ""
        }
        return this.clearSlashes(fragment) as string
    },
    clearSlashes: function (path: string) {
        return path.toString().replace(/\/$/, "").replace(/^\//, "")
    },
    add: function (re: RegExp | string, handler: Function) {
        if (typeof re == "function") {
            handler = re
            re = ""
        }
        this.routes.push({ re: re, handler: handler })
        return this
    },
    remove: function (param: any) {
        for (var i = 0, r; i < this.routes.length, (r = this.routes[i]); i++) {
            if (r.handler === param || r.re.toString() === param.toString()) {
                this.routes.splice(i, 1)
                return this
            }
        }
        return this
    },
    flush: function () {
        this.routes = []
        this.mode = null
        this.root = "/"
        this.lastFragment = ""
        return this
    },
    check: function (f: string) {
        var fragment = f || this.getFragment()
        this.routes.some(function (route) {
            var match = fragment == route.re || (route.re.test && route.re.test(fragment))
            if (match) {
                route.handler.apply({}, [fragment, route.re])
                return true
            }
        })
        return this
    },
    listen: function () {
        var self = this
        var current = self.getFragment()
        this.lastFragment = current

        var fn = function () {
            if (current !== self.getFragment()) {
                self.lastFragment = current
                current = self.getFragment()
                self.check(current)
            }
        }

        if ("onhashchange" in window) {
            ;(function (onhashchange) {
                window.onhashchange = function () {
                    if (typeof onhashchange == "function") {
                        //@ts-ignore
                        onhashchange.apply(window, arguments)
                    }
                    fn()
                }
            })(window.onhashchange)
        } else {
            clearInterval(this.interval)
            this.interval = setInterval(fn, 50)
        }

        return this
    },
    navigate: function (path: string) {
        path = path ? path.replace(/^#/, "") : ""
        if (this.mode === "history") {
            //@ts-ignore
            history.pushState(null, null, this.root + this.clearSlashes(path))
        } else {
            window.location.hash = path
        }
        return this
    }
}

const router = Router
router.config({ mode: "hash", root: "/sms/" })

let r = <any>router
r.add = (function (add) {
    return function (this: any) {
        add.apply(this, arguments)
        return this
    }
})(r.add)

export module navigation {
    export function toggleLog(): void {
        getterHelpers.useVue()?.toggleLog()
    }
}

router
    .add(/^password-reset-tenant-([^-]+?)-hash-(.+)$/, function () {
        useRouterStore().setRouter("password-reset")
    })
    .add(/^email-subscription-tenant-([^-]+?)-token-(.+)$/, (template: string, re: RegExp) => {
        const token = template.match(re)![2]
        useRouterStore().setRouter("email-subscription", undefined, token)
    })
    .add(
        /^android-code-enrollment-tenant-([^-]+?)-enrollmentId-(.+)$/,
        (template: string, re: RegExp) => {
            const enrollmentId = template.match(re)![2]
            useRouterStore().setRouter("android-code-enrollment", undefined, enrollmentId)
        }
    )
    .add(/^ios-code-enrollment-enrollmentId-(.+)$/, (template: string, re: RegExp) => {
        const enrollmentId = template.match(re)![1]
        useRouterStore().setRouter("ios-code-enrollment", undefined, enrollmentId)
    })
    .add(/^cloud-shield-status$/, (template: string, re: RegExp) => {
        useRouterStore().setRouter("cloud-shield-status", undefined)
    })
    .add(/^apple-account-based-enrollment$/, (template: string, re: RegExp) => {
        useRouterStore().setRouter("apple-account-based-enrollment", undefined)
    })
    .add(/^login$/, async function () {
        useRouterStore().setRouter("login")
    })
    .add(/^login-rsp-2fa-error$/, async function () {
        useRouterStore().setRouter("login", "rsp2faError")
    })
    .add(/logout/, async function () {
        queries.unifiedSecurity.logout()
    })
    .add(/^logout/, async function () {
        queries.unifiedSecurity.logout()
    })
    .add(/^show-tenant-([^-]+?)-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("tenant-dashboard")
    })
    .add(
        /^show-tenant-(.+?)-my-mobile-security-dashboard$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("msMyDashboard")
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("msDashboardTree")
        }
    )
    .add(/^show-tenant-(.+?)-mobile-security-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("msDashboard")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("msDashboardTree")
    })
    .add(/^show-tenant-(.+?)-inventory-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory")
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editVPN", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-device-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editDevice", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-android-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editAndroid", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-enterprise-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editEnterprise", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-usc-utm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editUscUtm", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-inventory-windows-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("inventory", "editWindowsVpn", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(
        /^edit-tenant-(.+?)-inventory-reportingUtm-(.+)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let objectId = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("inventory", "editReportingUtm", objectId)
            useRouterStore().openSubmenu("general")
        }
    )
    .add(/^show-tenant-(.+?)-vpp-assets-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(
        /^show-tenant-(.+?)-windows-enrollmenttokens-dashboard$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("windowsEnrollmentTokens")
            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(/^add-tenant-(.+?)-windows-enrollmenttoken$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsEnrollmentTokens", "add")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^edit-tenant-(.+?)-windows-enrollmenttoken-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let objectId = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("windowsEnrollmentTokens", "edit", objectId)
            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(
        /^add-tenant-(.+?)-windows-enrollment-license-conditions-dialog$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)

            useRouterStore().setRouter("windowsEnrollmentTokens", "add")
            products.unifiedSecurityConsole.windowsEnrollmentTokens.showWindowsLicenseConditions(
                tenantDomain,
                `add-tenant-${tenantDomain}-windows-enrollmenttoken`
            )

            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(
        /^add-tenant-(.+?)-windows-enrollment-privacy-policy-dialog$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)

            useRouterStore().setRouter("windowsEnrollmentTokens", "add")
            products.unifiedSecurityConsole.windowsEnrollmentTokens.showWindowsPrivacyPolicy(
                tenantDomain,
                `add-tenant-${tenantDomain}-windows-enrollmenttoken`
            )

            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(/^show-tenant-(.+?)-windows-vpns-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^add-tenant-(.+?)-windows-vpn$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns", "add")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-windows-vpns-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsVpns", "details", objectId)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-vpp-asset-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets", "details", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })

    .add(/^show-tenant-(.+?)-ios-apps-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-ios-apps-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-ios-app$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps", "add")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^edit-tenant-(.+?)-ios-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosApps", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })

    .add(/^show-tenant-(.+?)-apps-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-apps-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-app$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps", "add")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^edit-tenant-(.+?)-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^delete-tenant-(.+?)-app-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apps")
        dialogs.mobileSecurity.renderDeleteAppDialog(tenantDomain, objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-roles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-roles-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vppassets", "details", undefined, undefined, objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^add-tenant-(.+?)-role$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles", "add")
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-role-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("roles", "edit", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-licenses-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("licenses")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-license-(.+?)-details$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("licenses", "details", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-microsoft-entra-id$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("microsoftEntraId")
        useRouterStore().openSubmenu("general")
    })
    .add(/^user-sync-azure-error-tenant-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("microsoftEntraId")
        useRouterStore().openSubmenu("general")
        function openDialog() {
            if (useVue().$refs.unifiedSecuritySettings) {
                useVue().$refs.unifiedSecuritySettings.getFailedAzureAdDialog()
            } else {
                setTimeout(function () {
                    openDialog()
                }, 500)
            }
        }
        openDialog()
    })
    .add(/^user-sync-azure-success-tenant-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("microsoftEntraId")
        useRouterStore().openSubmenu("general")
        function openDialog() {
            if (useVue().$refs.unifiedSecuritySettings) {
                useVue().$refs.unifiedSecuritySettings.getConfirmAzureAdDialog()
            } else {
                setTimeout(function () {
                    openDialog()
                }, 500)
            }
        }
        openDialog()
    })

    .add(/^show-tenant-(.+?)-users-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-users-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", undefined, undefined, undefined, searchString)
        useRouterStore().openSubmenu("general")
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
    })
    .add(/^add-tenant-(.+?)-user$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", "add")
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-user-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", "edit", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^add-tenant-(.+?)-uscZtProfiles-agb-dialog$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)

        useRouterStore().setRouter("uscZtProfiles", "add")
        miscDialogs.showUtmLicenseConditions(
            tenantDomain,
            `add-tenant-${tenantDomain}-uscZtProfiles`
        )

        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^add-tenant-(.+?)-uscZtProfiles-privacy-policy-dialog$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)

            useRouterStore().setRouter("uscZtProfiles", "add")
            miscDialogs.showUtmPrivacyPolicyDialog(
                tenantDomain,
                `add-tenant-${tenantDomain}-uscZtProfiles`
            )

            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(/^enroll-tenant-(.+?)-enterprise-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("enterpriseDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(
            tenantDomain.replace(".sms", ""),
            "enterpriseDevices"
        )
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(
        /^show-tenant-(.+?)-enterprise-devices-dashboard$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("enterpriseDevices")
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(
        /^show-tenant-(.+?)-enterprise-devices-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter(
                "enterpriseDevices",
                undefined,
                undefined,
                undefined,
                searchString
            )
            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(
        /^show-tenant-(.+?)-enterprise-device-(.+?)-details-?(.*)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let deviceId = template.match(re)![2]
            let tab = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("enterpriseDevices", "details", deviceId)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(/^enroll-tenant-(.+?)-android-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(
            tenantDomain.replace(".sms", ""),
            "enterpriseDevices"
        )
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(/^show-tenant-(.+?)-android-devices$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(/^show-tenant-(.+?)-android-devices-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidDevices", undefined, undefined, undefined, searchString)
        nextTick(() => {
            tenantHelpers.setItemlistSearchValue(searchString)
        })
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(
        /^show-tenant-(.+?)-android-device-(.+?)-details-?(.*)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let deviceId = template.match(re)![2]
            let tab = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("androidDevices", "details", deviceId)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(/^show-tenant-(.+?)-ios-devices-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(
        /^show-tenant-(.+?)-ios-devices-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("iosDevices", undefined, undefined, undefined, searchString)
            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("ios")
        }
    )
    .add(/^set-tenant-(.+?)-device-(.+?)-ownership$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let deviceId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("devices")
        dialogs.mobileSecurity.assignDeviceOwnership(
            tenantDomain.replace(".sms", ""),
            "mobileSecurity",
            "devices",
            deviceId
        )
        useRouterStore().openSubmenu("mobSec")
    })
    .add(
        /^show-tenant-(.+?)-ios-device-(.+?)-details-?(.*)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let deviceId = template.match(re)![2]
            let tab = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("iosDevices", "details", deviceId)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("ios")
        }
    )
    .add(/^enroll-tenant-(.+?)-ios-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")
        dialogs.mobileSecurity.enrollDeviceDialog(tenantDomain.replace(".sms", ""), "iosDevices")
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^invite-tenant-(.+?)-device$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosDevices")

        function openInviteDialog() {
            if (useVue().$refs.itemlist) {
                useVue().$refs.itemlist.renderInviteDeviceDialog()
            } else {
                setTimeout(function () {
                    openInviteDialog()
                }, 500)
            }
        }
        nextTick(function () {
            {
                openInviteDialog()
            }
        })
        useRouterStore().openSubmenu("mobSec")
    })
    .add(
        /^download-tenant-(.+?)-device-(.+)-mobileconfig$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let deviceId = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("iosDevices")
            queries.mobileSecurity.downloadMobileConfig(tenantDomain, deviceId)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("ios")
        }
    )

    .add(/^show-tenant-(.+?)-account-info$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("account-info")
    })
    .add(/^show-tenant-(.+?)-enterprise-dialog$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("mobileSecuritySettings")

        function openDialog() {
            if (useVue().$refs.mobileSecuritySettings) {
                useVue().$refs.mobileSecuritySettings.getEnterpriseDialog()
            } else {
                setTimeout(function () {
                    openDialog()
                }, 500)
            }
        }
        openDialog()
    })
    .add(/^show-tenant-(.+?)-zero-touch-dialog$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("mobileSecuritySettings")
        function openDialog() {
            if (useVue().$refs.mobileSecuritySettings) {
                useVue().$refs.mobileSecuritySettings.getConfirmZeroTouchDialog()
            } else {
                setTimeout(function () {
                    openDialog()
                }, 500)
            }
        }
        openDialog()
    })
    .add(/^show-tenant-(.+?)-privacylevel-config$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("privacy")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-audit-log$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("auditlog")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-dep-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(
        /^show-tenant-(.+?)-dep-profiles-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("depProfiles", undefined, undefined, undefined, searchString)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("ios")

            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
        }
    )
    .add(/^add-tenant-(.+?)-dep-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles", "add")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^edit-tenant-(.+?)-dep-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("depProfiles", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-android-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(
        /^show-tenant-(.+?)-android-profiles-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter(
                "androidProfiles",
                undefined,
                undefined,
                undefined,
                searchString
            )
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
        }
    )
    .add(/^add-tenant-(.+?)-android-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles", "add")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(/^edit-tenant-(.+?)-android-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("androidProfiles", "edit", decodeURI(objectId))
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(/^show-tenant-(.+?)-ios-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(
        /^show-tenant-(.+?)-ios-profiles-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("iosProfiles", undefined, undefined, undefined, searchString)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("ios")
            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
        }
    )
    .add(/^add-tenant-(.+?)-ios-profile$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles", "add")
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^edit-tenant-(.+?)-ios-profile-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("iosProfiles", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-vpns-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns")
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^show-tenant-(.+?)-vpns-dashboard-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns", undefined, undefined, undefined, searchString)
        useRouterStore().openSubmenu("mobSec")
    })

    .add(/^show-tenant-(.+?)-secureDnsProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles")
        useRouterStore().openSubmenu("secureDns")
    })

    .add(/^show-tenant-(.+?)-threat-visualizer$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("threatVisualizer")
    })

    .add(/^show-tenant-(.+?)-secureDnsProfiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter(
            "secureDnsProfiles",
            undefined,
            undefined,
            undefined,
            searchString
        )
        useRouterStore().openSubmenu("secureDns")
    })
    .add(/^add-tenant-(.+?)-secureDnsProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles", "add")
        useRouterStore().openSubmenu("secureDns")
    })
    .add(/^edit-tenant-(.+?)-secureDnsProfiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDnsProfiles", "edit", objectId)
        useRouterStore().openSubmenu("secureDns")
    })
    .add(/^show-tenant-(.+?)-secureDns-stats?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDns-stats")

        useRouterStore().openSubmenu("secureDns")
    })
    .add(/^show-tenant-(.+?)-secureDns-logs?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("secureDns-logs")

        useRouterStore().openSubmenu("secureDns")
    })

    .add(/^show-tenant-(.+?)-uscZtProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-uscZtProfiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles", undefined, undefined, undefined, searchString)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^add-tenant-(.+?)-uscZtProfiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles", "add")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^edit-tenant-(.+?)-uscZtProfiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscZtProfiles", "edit", objectId)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })

    .add(/^show-tenant-(.+?)-windows-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-windows-profiles-(.+?)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let searchString = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles", undefined, undefined, undefined, searchString)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^add-tenant-(.+?)-windows-profiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles", "add")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^edit-tenant-(.+?)-windows-profiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("windowsProfiles", "edit", objectId)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })

    .add(/^add-tenant-(.+?)-vpn$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns", "add")
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^edit-tenant-(.+?)-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^delete-tenant-(.+?)-vpn-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("vpns")
        dialogs.mobileSecurity.renderDeleteVPNDialog(tenantDomain, objectId)
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^show-tenant-(.+?)-certificates-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("certificates")
        useRouterStore().openSubmenu("mobSec")
    })
    .add(/^add-tenant-(.+?)-certificate$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("certificates")
        products.mobileSecurity.certificates.renderAddCertificateDialog(tenantDomain)
        useRouterStore().openSubmenu("mobSec")
    })
    .add(
        /^show-tenant-([^-]+?)-mobile-security-settings$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("mobileSecuritySettings")
        }
    )
    .add(/^show-tenant-(.+?)-stats-dashboard?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("stats-dashboard")
        if (/^deanonymize-tenant/.test(Router.lastFragment)) {
            return
        }
        useRouterStore().openSubmenu("mobSec")
    })
    .add(
        /^show-tenant-(.+?)-stats-dashboard-(.+?)-(.+?)?$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchPeriod = template.match(re)![2]
            let searchRelate = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("stats-dashboard")

            const setSearch = () => {
                if (useVue().$refs.statspage) {
                    useVue().$refs.statspage.search.period = searchPeriod
                    useVue().$refs.statspage.search.type = searchRelate
                } else {
                    // component not yet rendered, retry in 1s
                    setTimeout(() => {
                        setSearch()
                    }, 1000)
                }
            }
            setSearch()

            if (/^deanonymize-tenant/.test(Router.lastFragment)) {
                return
            }
            useRouterStore().openSubmenu("mobSec")
        }
    )
    .add(/^deanonymize-tenant-(.+?)-device-(.+?)?$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let anonymousDeviceId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        dialogs.mobileSecurity.deanonymizeDevice(
            tenantDomain.replace(".sms", ""),
            "devices",
            anonymousDeviceId
        )
    })
    .add(/^show-tenant-(.+?)-notifications-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications")
        useRouterStore().openSubmenu("general")
    })
    .add(
        /^show-tenant-(.+?)-notifications-dashboard-(.+)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let search = decodeURIComponent(template.match(re)![2])
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("notifications", undefined, undefined, undefined, search)
            useRouterStore().openSubmenu("general")
        }
    )
    .add(/^add-tenant-(.+?)-notification$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications", "add")
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-notification-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications", "edit", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^delete-tenant-(.+?)-notification-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("notifications")
        dialogs.unifiedSecurity.renderDeleteNotificationDialog(tenantDomain, objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(
        /^show-tenant-(.+?)-zerotouchconfigurations-dashboard$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("zeroTouchConfigurations")
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(
        /^show-tenant-(.+?)-zerotouchconfigurations-dashboard-(.+?)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let searchString = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter(
                "zeroTouchConfigurations",
                undefined,
                undefined,
                undefined,
                searchString
            )
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
            nextTick(() => {
                tenantHelpers.setItemlistSearchValue(searchString)
            })
        }
    )
    .add(/^add-tenant-(.+?)-zerotouchconfiguration$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let customerId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("zeroTouchConfigurations", "add", undefined, customerId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(
        /^edit-tenant-(.+?)-zerotouchconfiguration-(.+)-(.+)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let customerId = template.match(re)![2]
            let objectId = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("zeroTouchConfigurations", "edit", objectId, customerId)
            useRouterStore().openSubmenu("mobSec")
            useRouterStore().openSubmenu("android")
        }
    )
    .add(/^dashboard/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("dashboard")
        let firstAccountId = Object.keys(useStore().state.session.accounts)[0]
        router.navigate(
            "show-tenant-" + tenantHelpers.getTenantDomain(firstAccountId) + "-dashboard"
        )
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("android")
    })
    .add(/^show-tenant-(.+?)-my-usc-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscMyDashboard")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
        useRouterStore().openSubmenu("uscDashboardTree")
    })
    .add(/^show-tenant-(.+?)-usc-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscDashboard")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
        useRouterStore().openSubmenu("uscDashboardTree")
    })
    .add(/^show-tenant-(.+?)-usc-profiles-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^show-tenant-(.+?)-usc-profiles-dashboard-(.+)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let search = decodeURIComponent(template.match(re)![2])
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("uscProfiles", "edit", undefined, undefined, search)
            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(/^edit-tenant-(.+?)-usc-profiles-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles", "edit", objectId)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^add-tenant-(.+?)-usc-profiles$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscProfiles", "add")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-usc-utms-commandcenter$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscCommandCenter")
    })
    .add(/^show-tenant-(.+?)-usc-utms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^show-tenant-(.+?)-usc-utms-dashboard-action-(.+)-utm-(.+)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let actionType = template.match(re)![2]
            let utmId = template.match(re)![3]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("uscUtms")
            useRouterStore().openSubmenu("unifiedSecurityConsole")

            function doActionOnInitializedAccount() {
                if (useVue()?.activeAccount?.initialized) {
                    if (actionType == "websession") {
                        ;(config.canUseNewObjectType("uscUtms")
                            ? products.unifiedSecurityConsole.uscUtms
                            : objectStores.uscUtms.dialogs
                        ).renderNewWebSessionDialog(tenantHelpers.getAccountId(tenantDomain), utmId)
                    }
                } else {
                    setTimeout(() => {
                        doActionOnInitializedAccount()
                    }, 1000)
                }
            }
            doActionOnInitializedAccount()
        }
    )
    .add(/^show-tenant-(.+?)-usc-utms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms", undefined, undefined, undefined, search)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(/^show-tenant-(.+?)-usc-utms-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("uscUtms", "details", objectId)
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^show-tenant-(.+?)-unified-security-console-settings$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("unifiedSecurityConsoleSettings", undefined, undefined)
            useRouterStore().openSubmenu("unifiedSecurityConsole")
        }
    )
    .add(/^show-tenant-(.+?)-utms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms")
        useRouterStore().openSubmenu("laas")
    })
    .add(/^show-tenant-(.+?)-utms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", undefined, undefined, undefined, search)
        useRouterStore().openSubmenu("laas")
    })
    .add(/^edit-tenant-(.+?)-utm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", "edit", objectId)
        useRouterStore().openSubmenu("laas")
    })

    .add(/^show-tenant-(.+?)-usrutms-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms")
        useRouterStore().openSubmenu("laas")
    })
    .add(/^show-tenant-(.+?)-usrutms-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", undefined, undefined, undefined, search)
        useRouterStore().openSubmenu("laas")
    })
    .add(/^edit-tenant-(.+?)-usrutm-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("usrUtms", "edit", objectId)
        useRouterStore().openSubmenu("laas")
    })

    .add(/^show-tenant-(.+?)-reports-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports")
        useRouterStore().openSubmenu("laas")
    })
    .add(/^show-tenant-(.+?)-reports-dashboard-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let search = decodeURIComponent(template.match(re)![2])
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports", undefined, undefined, undefined, search)
        useRouterStore().openSubmenu("laas")
    })
    .add(/^edit-tenant-(.+?)-report-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("reports", "edit", objectId)
        useRouterStore().openSubmenu("laas")
    })
    .add(/^show-tenant-(.+?)-warnings$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("warnings")
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-branding$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("branding")
    })
    .add(/^show-tenant-(.+?)-user-(.+?)-details-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        let tab = template.match(re)![3]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("users", "details", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-homescreenlayouts-dashboard/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^add-tenant-(.+?)-homescreenlayout/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts", "add")
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^edit-tenant-(.+?)-homescreenlayout-?(.*)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("homescreenlayouts", "edit", objectId)
        useRouterStore().openSubmenu("mobSec")
        useRouterStore().openSubmenu("ios")
    })
    .add(/^show-tenant-(.+?)-apikeys-dashboard$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys")
        useRouterStore().openSubmenu("general")
    })
    .add(/^add-tenant-(.+?)-apikey$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys", "add")
        useRouterStore().openSubmenu("general")
    })
    .add(/^edit-tenant-(.+?)-apikey-(.+)$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        let objectId = template.match(re)![2]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("apiKeys", "edit", objectId)
        useRouterStore().openSubmenu("general")
    })
    .add(/^show-tenant-(.+?)-usc-vpn-configuration$/, async (template: string, re: RegExp) => {
        let tenantDomain = template.match(re)![1]
        useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
        useRouterStore().setRouter("adaptiveSecureConnectConfiguration")
        useRouterStore().openSubmenu("unifiedSecurityConsole")
    })
    .add(
        /^show-tenant-(.+?)-usc-vpn-configuration-highlight-?(.*)$/,
        async (template: string, re: RegExp) => {
            let tenantDomain = template.match(re)![1]
            let rowId = template.match(re)![2]
            useStore().dispatch(ActionTypes.switchAccount, tenantDomain)
            useRouterStore().setRouter("adaptiveSecureConnectConfiguration")
            useRouterStore().openSubmenu("unifiedSecurityConsole")
            function doActionOnInitializedAccount() {
                if (useVue()?.activeAccount?.initialized && useVue()?.$refs.adaptiveSecureConnect) {
                    useVue()?.$refs.adaptiveSecureConnect?.highLightRowById(rowId)
                } else {
                    setTimeout(() => {
                        doActionOnInitializedAccount()
                    }, 1000)
                }
            }
            doActionOnInitializedAccount()
        }
    )
    .add(/^sandbox/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("sandbox")
    })
    .add(/^docs/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("docs")
    })
    .add(/^pluginLicenses/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("pluginLicenses")
    })
    .add(/^refresh/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("refreshing")
    })
    .add(/^404$/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("404")
    })
    .add(/^404-(.+?)$/, async (template: string, re: RegExp) => {
        let objectId = template.match(re)![1]
        useRouterStore().setRouter("404", undefined, objectId)
    })
    .add(/^(.+?)$/, async (template: string, re: RegExp) => {
        useRouterStore().setRouter("404")
    })

export default router
