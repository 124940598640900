import products from "@/classes/objectTypes"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import resourcePages, { type PageOptions } from "@/resources/resource-pages"
import { defineStore } from "pinia"

const useRouterStore = defineStore({
    id: "router",
    state: () => ({
        activePage: <string | undefined>undefined,
        context: <string | undefined>undefined,
        objectId: <string | undefined>undefined,
        zeroTouchCustomerId: <string | undefined>undefined,
        search: <string | undefined>undefined,
        openedSubmenus: <string[]>[]
    }),
    getters: {
        getRouter(state) {
            return {
                page: state.activePage,
                context: state.context,
                objectId: state.objectId,
                zeroTouchCustomerId: state.zeroTouchCustomerId,
                search: state.search
            }
        },
        getActivePage(state) {
            return state.activePage
        },
        getContext(state) {
            return state.context
        },
        getObjectId(state) {
            return state.objectId
        },
        getZeroTouchCustomerId(state) {
            return state.zeroTouchCustomerId
        },
        getSearch(state) {
            return state.search
        },
        isSubmenuOpened: (state) => {
            return (id: string) => {
                return state.openedSubmenus.indexOf(id) != -1
            }
        },
        getActivePageMeta(state) {
            return jsonHelpers.jsonSearchPair(resourcePages, "id", state.activePage || "") as
                | PageOptions
                | -1
        },
        getPagesPath(state) {
            let result: PageOptions[] = []
            if (state.activePage) {
                result = jsonHelpers.getChildrenChain(
                    resourcePages,
                    "children",
                    "id",
                    state.activePage
                ) as PageOptions[]
            }
            return result
        }
    },
    actions: {
        /**
         * sets Router
         */
        setRouter(
            page: string,
            context: string | undefined = undefined,
            objectId: string | undefined = undefined,
            zeroTouchCustomerId: string | undefined = undefined,
            search: string | undefined = undefined
        ) {
            const setValues = () => {
                this.activePage = page
                this.context = context
                this.objectId = objectId
                this.zeroTouchCustomerId = zeroTouchCustomerId
                this.search = search
            }

            if (
                this.activePage == "adaptiveSecureConnectConfiguration" &&
                page !== this.activePage
            ) {
                let activeAccountId = getterHelpers.useStore().state.session.activeAccountId || ""
                let topologies = products.unifiedSecurityConsole.topologies
                    .useStore?.()
                    .getObjectStoreObjects(activeAccountId)
                if (
                    Array.isArray(topologies) &&
                    topologies.length > 0 &&
                    products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload
                ) {
                    products.unifiedSecurityConsole.topologies.view.getUnpulishedChangesDialog(
                        activeAccountId,
                        () => {
                            setValues()
                        }
                    )
                } else {
                    setValues()
                }
            } else {
                setValues()
            }
        },
        setActivePage(page: string | undefined) {
            this.activePage = page
        },
        setContext(context: string | undefined) {
            this.context = context
        },
        setObjectId(objectId: string | undefined) {
            this.objectId = objectId
        },
        setZeroTouchCustomerId(id: string | undefined) {
            this.zeroTouchCustomerId = id
        },
        setSearch(search: string | undefined) {
            this.search = search
        },
        openSubmenu(id: string) {
            this.openedSubmenus.push(id)
        },
        closeSubmenu(id: string) {
            this.openedSubmenus = this.openedSubmenus.filter((existingId) => {
                return existingId != id
            })
        },
        toggleSubmenu(id: string) {
            if (this.isSubmenuOpened(id)) {
                this.closeSubmenu(id)
            } else {
                this.openSubmenu(id)
            }
        }
    }
})
export default useRouterStore
